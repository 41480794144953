import { ComponentType, lazy } from 'react';

type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>;

const lazyRetry = <T, >(componentImport: () => ComponentPromise<T>) => {
    const promise = new Promise<{ default: ComponentType<T> }>((resolve, reject) => {
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('refreshed') || 'false'
        );
        // try to import the component
        componentImport()
            .then((component) => {
                window.sessionStorage.setItem('refreshed', 'false');
                resolve(component);
            }).catch((error) => {
                if (!hasRefreshed) {
                    window.sessionStorage.setItem('refreshed', 'true');
                    return window.location.reload();
                }
                reject(error);
            });
    });

    return lazy(() => promise);
};

export default lazyRetry;