import styled, { CreateStyled } from '@emotion/styled';

export interface Theme {
    colors: {
        primary: string,
        primary_hover: string,
        primary2: string,
        primary3: string,
        primary4: string,
        primary5: string,
        primaryLight: string,
        secondary: string,
        secondary_hover: string,
        red: string,
        orange: string,
        lightOrange: string;
        medium: string,
        black: string,
        white: string,
        light: string,
        border: string,
        placeholder: string,
        dark: string,
        yellow: string,
        gray: {
            [key: number]: string;
        },
        blue: string,
        magenta: string,
        headerBackground: string,
        headerText: string,
    };
    fontSizes: {
        normal: number,
        h1: number,
        h2: number,
        h3: number,
        h4: number,
        bigger: number,
        smaller: number
    };
    borderRadius: {
        small: string,
        normal: string
    };
    constants: {
        borderRadius: number,
        spacer: number,
        panelPadding: number,
        menuWidth: number,
        topBarHeight: number, // old
        footerHeight: number,
        breadCrumbHeight: number,
        header: {
            height: string
        }
    };
    brand: {
        primary: string,
        secondary: string,
        brandIcon: string
    }
    breakpoints: number[];
    logo: string;
}

export default styled as CreateStyled<Theme>;
