export enum Claim {
    UserId = 'abvhaukes.userid',
    UserName = 'abvhaukes.username',
    Roles = 'abvhaukes.role',
    Rights = 'abvhaukes.right',
    FirstName = 'abvhaukes.firstName',
    Insertion = 'abvhaukes.insertion',
    LastName = 'abvhaukes.lastName',
    EmployeeInitials = 'abvhaukes.employeeInitials',
    ProfilePhotoCdnHash = 'abvhaukes.profilePhotoCdnHash',
    slug = 'abvhaukes.slug',
    IsWvb = 'abvhaukes.isWvb',
    SecLevel = 'abvhaukes.secLevel', 
    ClientId = 'abvhaukes.clientId',
    ClientName = 'abvhaukes.clientName',
    CustomerUserPermissions = 'abvhaukes.customerUserPermission',
    UserProjectPermission = 'abvhaukes.userProjectPermission',
    UserObjectPermission = 'abvhaukes.userObjectPermission',
    ClientProjectPermission = 'abvhaukes.clientProjectPermission',
    ClientObjectPermission = 'abvhaukes.clientObjectPermission',
    ClientToClientPermission = 'abvhaukes.clientToClientPermission'
}
