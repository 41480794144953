import React, { ReactNode } from 'react';
import { ReactFCWithChildren } from 'react-extension';
import { Wrapper, Spinner, Inside, Title } from './styles';

interface LoadingProps {
    icon?: ReactNode;
    children?: string;
    noMarginTop?: boolean;
    noWidth?: boolean;
    noMargin?: boolean;
    small?: boolean;
}

const Loading: ReactFCWithChildren<LoadingProps> = ({ icon, children, noMarginTop, noWidth = false, noMargin = false, small = false }) => {
    return (
        <Wrapper noMarginTop={noMarginTop} noMargin={noMargin} small={small} noWidth={noWidth}>
            <Spinner small={small}>
                <Inside small={small} />
                <Inside small={small} />
                {icon}
            </Spinner>
            {children && <Title>{children}</Title>}
        </Wrapper>
    );
};

export default Loading;
