import Icon from 'components/Icon';
import lazyRetry from 'components/LazyRetry';
import Loading from 'components/NewLoading';
import React, { FC, Suspense } from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate } from 'react-router-dom';
import theme from 'static/themes/theme';

const LoginLayout = lazyRetry(() => import('layouts/Login'));
const MainLayout = lazyRetry(() => import('layouts/Main'));
const AdminLayout = lazyRetry(() => import('layouts/Admin'));
const DefaultLayout = lazyRetry(() => import('layouts/Default'));

const LoginPage = lazyRetry(() => import('screens/Login'));
const TfaPage = lazyRetry(() => import('screens/Tfa'));
const ForgotPasswordPage = lazyRetry(() => import('screens/ForgotPassword'));
const ResetPasswordPage = lazyRetry(() => import('screens/ResetPassword'));
const LoginWithUsername = lazyRetry(() => import('screens/LoginWithUsername'));

const ObjectsPage = lazyRetry(() => import('screens/Objects'));
const MapPage = lazyRetry(() => import('screens/Map'));
const LocationPage = lazyRetry(() => import('screens/Location'));
const InvoicesPage = lazyRetry(() => import('screens/Invoices'));
const PlanningPage = lazyRetry(() => import('screens/Planning'));

const ManageCustomerUsersPage = lazyRetry(() => import('screens/ManageCustomerUsers'));
const ManageCustomerUserInvitationsPage = lazyRetry(() => import('screens/ManageCustomerUserInvitations'));
const AdminInviteCustomerUserPage = lazyRetry(() => import('screens/AdminInviteCustomerUser'));
const RegisterCustomerUserPage = lazyRetry(() => import('screens/RegisterCustomerUser'));
const EditCustomerUserPage = lazyRetry(() => import('screens/EditCustomerUser'));
const EditCustomerUserPermissionsPage = lazyRetry(() => import('screens/EditCustomerUserPermissions'));
const ManageClientsPage = lazyRetry(() => import('screens/ManageClients'));
const ManageClientProjectsPage = lazyRetry(() => import('screens/ManageClientProjects'));
const ManageClientUsersPage = lazyRetry(() => import('screens/ManageClientUsers'));
const ManageClientObjectsPage = lazyRetry(() => import('screens/ManageClientObjects'));
const ManageClientPermissionPage = lazyRetry(() => import('screens/ManageClientPermissions'));
const EditClientPortalSettingsPage = lazyRetry(() => import('screens/EditClientPortalSettings'));
const EditObjectPage = lazyRetry(() => import('screens/EditObject'));
const ProfilePage = lazyRetry(() => import('screens/Profile'));
const IdeasPage = lazyRetry(() => import('screens/Ideas'));
const ManageLogsPage = lazyRetry(() => import('screens/ManageLogs'));
const ManageGeneral = lazyRetry(() => import('screens/ManageGeneral'));
const PlanningTool = lazyRetry(() => import('screens/PlanningTool'));
const ProjectsOverview = lazyRetry(() => import('screens/ProjectsOverview'));
const ManageEmployeesPage = lazyRetry(() => import('screens/ManageEmployees'));
const EditEmployeePage = lazyRetry(() => import('screens/EditEmployee'));

const ManageEmployeeInvitationsPage = lazyRetry(() => import('screens/ManageEmployeeInvitations'));
const AdminInviteEmployeePage = lazyRetry(() => import('screens/AdminInviteEmployee'));
const RegisterEmployeePage = lazyRetry(() => import('screens/RegisterEmployee'));

const routes = (providers: JSX.Element) => {
    const RootComponent = (
        <Suspense fallback={<Loading icon={<Icon icon={theme.brand.brandIcon} />} />}>
            {providers}
        </Suspense>
    );

    return createBrowserRouter(
        createRoutesFromElements(
            <Route element={RootComponent}>
                <Route index element={<DefaultLayout />} />

                <Route path="account" element={<LoginLayout />}>
                    <Route element={<LoginPage />} path="login" />
                    <Route element={<TfaPage />} path="tfa" />
                    <Route element={<RegisterCustomerUserPage />} path="register/customer/:code" />
                    <Route element={<RegisterEmployeePage />} path="register/employee/:code" />
                    <Route element={<ForgotPasswordPage />} path="forgotpassword" />
                    <Route element={<ResetPasswordPage />} path="resetpassword" />
                    <Route element={<LoginWithUsername />} path="loginwithusername/:username" />
                </Route>

                <Route element={<LoginLayout />} path=":slug/account">
                    <Route element={<LoginPage />} path="login" />
                    <Route element={<RegisterCustomerUserPage />} path="register/customer/:code" />
                </Route>

                <Route element={<AdminLayout />} path="admin">
                    <Route element={<Navigate replace to="/admin/projects" />} index />
                    <Route element={<ManageCustomerUsersPage />} path="users/customer" />
                    <Route element={<ManageCustomerUserInvitationsPage />} path="users/customer/invited" />
                    <Route element={<AdminInviteCustomerUserPage />} path="users/customer/invite/:clientId" />
                    <Route element={<EditCustomerUserPage />} path="users/customer/:id/edit" />
                    <Route element={<EditCustomerUserPermissionsPage />} path="users/customer/:id/edit/permissions" />
                    <Route element={<ProfilePage />} path="profile" />
                    <Route element={<IdeasPage />} path="ideas" />
                    <Route element={<ManageLogsPage />} path="logs" />
                    <Route element={<ManageGeneral />} path="general" />
                    <Route element={<ProjectsOverview />} path="projects" />
                    <Route element={<ManageEmployeesPage />} path="employees" />
                    <Route element={<EditEmployeePage />} path="employees/:id/edit" />
                    <Route element={<ManageEmployeeInvitationsPage />} path="employees/invited" />
                    <Route element={<AdminInviteEmployeePage />} path="employees/invite" />
                    {
                        process.env.REACT_APP_TENANT === 'haukes' &&
                        <>
                            <Route element={<PlanningTool />} path="planning" />
                            <Route element={<ManageClientsPage />} path="clients" />
                            <Route element={<ManageClientProjectsPage />} path="clients/:id/projects" />
                            <Route element={<ManageClientUsersPage />} path="clients/:id/users" />
                            <Route element={<ManageClientObjectsPage />} path="clients/:clientId/objects" />
                            <Route element={<ManageClientPermissionPage />} path="clients/:clientId/permissions" />
                            <Route element={<EditClientPortalSettingsPage />} path="clients/:id/edit" />
                            <Route element={<EditObjectPage />} path="clients/:clientId/objects/:objectId/edit" />
                            <Route element={<LocationPage />} path="clients/:clientId/objects/:id/facilities" />
                        </>
                    }
                </Route>
                {
                    process.env.REACT_APP_TENANT === 'haukes' &&
                    <Route element={<MainLayout />} path=":slug/">
                        <Route element={<ObjectsPage />} index />
                        <Route element={<ObjectsPage />} path="objecten" />
                        <Route element={<MapPage />} path="map" />
                        <Route element={<LocationPage />} path="location/:id" />
                        <Route element={<InvoicesPage />} path="invoices" />
                        <Route element={<PlanningPage />} path="planning" />
                        <Route element={<ProfilePage />} path="profile" />
                        <Route element={<IdeasPage />} path="ideas" />
                    </Route>
                }
            </Route>
        )
    );
};

export interface RouteProps {
    // Providers that need access to the route context can be defined in this component
    providers: JSX.Element;
}

const AppRoutes: FC<RouteProps> = (props) => (
    <RouterProvider
        router={routes(props.providers)}
    />
);

export default AppRoutes;
