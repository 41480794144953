import { css, Global } from '@emotion/core';
import React, { useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import theme from 'static/themes/theme';
import { ReactFCWithChildren } from 'react-extension';

export interface IconProps {
    size?: number;
    color?: string;
    icon: string;
    className?: string;
    stroke?: string;
    strokeWidth?: number;
}

const Icon: ReactFCWithChildren<IconProps> = ({ size = 1.5, color = theme.colors.dark, icon, className, stroke, strokeWidth }) => {
    const styles = css`
        .icon-wrapper {
            span {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    `;

    const beforeInjection = useCallback((svg: SVGElement) => {
        svg.setAttribute('style', `width: ${size}rem; height: ${size}rem;`);

        if (color) {
            svg.setAttribute('fill', color);
        }

        if (stroke) {
            svg.setAttribute('stroke', stroke);
        }

        if (strokeWidth) {
            svg.setAttribute('stroke-width', strokeWidth.toString());
        }
    }, [size, color, stroke, strokeWidth]);

    return (
        <>
            <Global styles={styles} />
            <ReactSVG
                wrapper="span"
                className={classnames('icon-wrapper', className)}
                beforeInjection={beforeInjection}
                src={require(`static/icons/${icon}.svg`)}
            />
        </>
    );
};

export default Icon;
