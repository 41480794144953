import React from 'react';
import { createRoot } from 'react-dom/client';
import { Normalize } from '@smooth-ui/core-em';
import { css, Global } from '@emotion/core';
import theme from 'static/themes/theme';
import { ModalProvider } from 'react-modal-hook';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import 'moment/locale/nl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionProvider } from 'contexts/sessionContext';
import { ThemeProvider } from 'emotion-theming';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './AppRoutes';
import { Outlet } from 'react-router-dom';

moment.locale('nl');

const globalStyle = css({
    '*, *:before, *:after': {
        boxSizing: 'border-box'
    },
    '::-webkit-scrollbar': {
        width: 7,
        background: '#ffffff'
    },
    '::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        background: '#7d7d7d'
    },
    '::-webkit-scrollbar-thumb:window-inactive': {
        background: '#c7c7c7'
    },
    html: {
        color: theme.colors.secondary
    },
    body: {
        position: 'relative',
        margin: 0,
        padding: 0,
        overflowX: 'hidden',
        fontFamily: `${process.env.REACT_APP_TENANT === 'haukes' ? 'Montserrat' : 'Ubuntu'}, sans-serif`,
        fontWeight: 400,
        color: theme.colors.secondary,
        backgroundColor: theme.colors.light,
        WebkitFontSmoothing: 'antialiased',
        WebkitOverflowScrolling: 'touch',
        MozOsxFontSmoothing: 'grayscale'
    },
    code: {
        fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace`
    },
    hr: {
        marginTop: theme.constants.spacer,
        marginBottom: theme.constants.spacer,
        border: 0,
        borderBottom: '1px solid ' + theme.colors.border,
        boxSizing: 'border-box'
    },
    '.smaller': {
        fontSize: theme.fontSizes.smaller
    },
    p: {
        marginTop: 0,
        marginBottom: '1rem'
    },
    'li, p': {
        lineHeight: 1.5
    },
    ul: {
        marginTop: 0
    },
    'b, strong': {
        fontWeight: 700
    },
    a: {
        textDecoration: 'none',
        color: 'inherit',
        cursor: 'pointer'
    },
    img: {
        verticalAlign: 'middle'
    },
    'h1, h2, h3, h4, h5, h6': {
        textTransform: 'uppercase'
    },
    '#layers': {
        zIndex: 1000
    }
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false
        },
    },
});

const Root = () => {
    return (
        <>
            <Normalize />
            <Global styles={globalStyle} />
            <ToastContainer />
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <SessionProvider>
                        <AppRoutes
                            providers={
                                <ModalProvider>
                                    <Outlet />
                                </ModalProvider>
                            }
                        />
                    </SessionProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </>
    );
};

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<Root />);
