import { css } from '@emotion/core';
import { rgba } from 'polished';
import styled from 'static/themes/styled';

export const Wrapper = styled.div<{ noMarginTop?: boolean, small: boolean, noWidth?: boolean, noMargin?: boolean }>`
    background-color: white;
    border-radius: 16px;
    width: ${({ noWidth }) => (noWidth ? 'auto' : '440px')};
    margin: ${({ noMarginTop }) => (noMarginTop ? 0 : 4.5)}rem auto 0;
    height: 15.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    ${({ small }) =>
        small && css`
        height: auto;
    `};

    ${({ noMargin }) =>
        noMargin && css`
        margin: 0!important;
    `};
`;

export const Spinner = styled.div<{ small: boolean }>`
    width: 8rem;
    height: 8rem;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    ${({ small }) =>
        small && css`
        width: 2rem;
        height: 2rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
    `};
`;

export const Inside = styled.div<{ small: boolean }>`
    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 8rem;
    height: 8rem;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme }) => theme.colors.primary} transparent transparent transparent;
    top: 0;
    left: 0;

    &:nth-of-type(1) {
        animation: none;
        border-color: ${({ theme }) => rgba(theme.colors.primary, 0.2)};
    }

    ${({ small }) =>
        small && css`
        width: 2rem;
        height: 2rem;
    `};
`;

export const Title = styled.span`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 0.875rem;
`;
